$brand-off: #aaaaaa !default;
$brand-dark: #353535;
$brand-light: #ffffff;

$background: $brand-light;

$font-light: $brand-light;
$brand-font: Arial, Helvetica, Verdana, sans-serif !default;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-dark: $brand-dark;
$font: #000000;

$font-size-super: 72px;
$font-size-larger: 48px;
$font-size-large: 36px;
$font-size-mediumer: 26px;
$font-size-medium: 21px;
$font-size: 18px;
$font-size-small: 16px;

$border-radius: 10px;

@import "atomic";

html,
body {
  color: $font;
  background: $background;
  width: 100%;
}

html {
  display: flex;
  justify-content: center;
}

body {
  font-family: $brand-font;
  font-size: $font-size;
  @media screen and (max-width: 768px) {
    font-size: $font-size-small;
  }
}

.container {
  //padding-top: 30px;
  padding-bottom: 30px;
  @media screen and (min-width: 992px) {
    width: 865px !important;
  }
}

mybooking-header {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 20pt;
  padding: 10pt;
  align-items: center;

  div.brand-img {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  div.contact {
    height: 100%;

    a {
      margin: auto;
      white-space: nowrap;
    }
  }

  div.environment-label {
    margin-left: auto;
    width: fit-content;
    color: white;
    background-color: turquoise;
    border-radius: 4px;
    padding: 5pt;
  }
}

app-root {
  div.my-booking-page {
    max-width: 865px;
    margin: 0 auto;
    padding: 0 15px;

    h2 {
      font-size: 36px;
    }

    @media (max-width: 600px) {
      padding: 0 5px;
    }

    div.info-section {
      > p {
        padding: 0 15px;
      }
    }

    div.payment {
      > p {
        padding: 0 15px;
      }
    }

    hr {
      margin: 0 20px;
    }

    div.services-total {
      display: flex;
      justify-content: space-between;
      column-gap: 10pt;

      padding: 5pt 15pt;
      font-size: 15px;

      em {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }

      @media (min-width: 600px) {
        justify-content: start;
      }
    }

    services-section {
      display: block;
      margin-bottom: 25pt;

      div.packages {
        margin: 0 10px;
      }
    }

    related-bookings {
      div.related-booking {
        margin: 5px 15px;

        display: flex;
        justify-content: space-between;
      }
    }

    div.footer {
      margin-top: 30px;

      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    font-size: $font-size-super;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 768px) {
      font-size: $font-size-larger;
    }
  }

  h2 {
    font-size: $font-size-larger;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 768px) {
      font-size: $font-size-large;
    }
  }

  h3 {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 768px) {
      font-size: $font-size-mediumer;
    }
  }

  h4 {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 768px) {
      font-size: $font-size-medium;
    }
  }

  .h4-alt {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 768px) {
      font-size: $font-size;
    }
  }

  a {
    font-weight: $font-weight-bold;
    text-decoration: none !important;
  }

  .status {
    padding: 10px;
    font-weight: $font-weight-bold;
    @media screen and (min-width: 768px) {
      width: 360px;
    }
  }

  .info-section {
    .col-xs-12 {
      .list-item {
        margin-bottom: 20px;
        @media screen and (max-width: 768px) {
          margin-bottom: 15px;
        }
      }
    }
  }

  .flex-center {
    display: flex;
    justify-content: center;
  }

  .form-group {
    label {
      font-weight: $font-weight-bold;
    }

    input {
      font-weight: $font-weight-bold;
    }

    textarea {
      font-weight: $font-weight-bold;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: $font-weight-medium;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: $font-weight-medium;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      font-weight: $font-weight-medium;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      font-weight: $font-weight-medium;
    }
  }

  .form-group.required label:after {
    content: "*";
    margin-left: 3px;
  }

  .clickable {
    cursor: pointer;
  }

  button {
    font-size: $font-size !important;
    color: $font-light;
    padding: 10px 20px !important;
    cursor: pointer;
    font-weight: $font-weight-bold !important;
    max-height: 50px;

    &.btn {
      border-radius: 30px;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    &.btn-large {
      padding-left: 75px !important;
      padding-right: 75px !important;
      @media screen and (max-width: 768px) {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
    }

    &.btn-medium {
      width: 130px;
    }
  }

  .btn:focus,
  .btn:active:focus,
  .btn.active:focus {
    outline: none !important;
  }

  .form-group {
    margin-bottom: 30px;

    label {
      font-size: $font-size-small;
    }

    .radio-title {
      color: $font-dark;
    }

    input {
      font-size: $font-size;
      padding: 22px 16px;
    }

    textarea {
      font-size: $font-size;
      padding: 12px 16px;
    }
  }

  .font-bold {
    font-weight: $font-weight-bold;
  }

  .flex-1 {
    display: flex;
    flex: 1;
  }

  .flex-end {
    justify-content: flex-end !important;
  }

  .well {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 25px;
    height: 70px;
    border: 0;
    min-height: 70px;
    margin-bottom: 40px;

    .well-title {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      flex: 4;
      font-weight: $font-weight-bold;
      margin: 0;
    }

    .well-images {
      display: flex;
      flex-wrap: nowrap;
      flex: 1;
    }

    .well-action {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }

    .img-base {
      height: 70px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    > .btn.active,
    .btn:active {
      background-image: none;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    @media screen and (max-width: 768px) {
      //height: 250px;
      flex-direction: column;

      .well-action {
        flex: 1;
        justify-content: space-between;
      }

      .well-title {
        flex: 1;
      }
    }
  }

  .flex-row-xs-col {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .p-0 {
    padding: 0;
  }

  .pb-20 {
    padding-bottom: 20px;
  }

  .m-10 {
    margin: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mtb-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mtb-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mlr-15 {
    margin: 0 15px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .mr-30 {
    margin-right: 30px;
  }

  .ml-30 {
    margin-left: 30px;
  }

  hr {
    margin-bottom: 0;
  }

  .layout-content {
    display: flex;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .center-text {
    text-align: center;
  }

  .list-item-container {
    display: flex;

    div {
      flex: 1;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .rb-check {
    display: flex;
    align-items: center;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .x-icon {
    display: flex;
    flex: 0 0 70px;
    align-self: stretch;
    align-items: center;

    &.x-icon-small {
      flex: 0 0 50px;
    }
  }

  .x-icon-end {
    display: flex;
    flex: 0 0 30px;
    align-self: stretch;
    align-items: center;
  }

  .x1-xs2 {
    flex: 1;
    @media screen and (max-width: 768px) {
      flex: 1.2;
    }
  }

  .x1 {
    flex: 1;
  }

  .x2 {
    flex: 2;
  }

  .x3 {
    flex: 3;
  }

  .x4 {
    flex: 4;
  }

  .list-item {
    display: flex;

    .list-icon {
      color: gray;
    }

    .list-title {
      font-size: $font-size-larger;
      font-weight: $font-weight-bold !important;
      @media screen and (max-width: 768px) {
        font-size: $font-size;
      }
    }

    .item-border-bottom {
      display: flex;
      border-bottom: 1px solid #ffffff;
      padding-bottom: 10px;
      padding-top: 11px;
    }
  }

  /* Hide the browser's default radio button */
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .outer-circle {
    background: white;
    border: 2.5px solid #b4b4b4;
    border-radius: 50%;
    min-height: 30px;
    min-width: 30px;
    position: relative;
  }

  .inner-circle {
    position: absolute;
    background: #8cb811;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    top: 50%;
    left: 50%;
    margin: -9px 0px 0px -9px;

    &.disabled {
      background-color: $brand-off;
      cursor: not-allowed;
    }
  }

  .flex-space-between {
    justify-content: space-between;
  }

  .radio-group {
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    color: $font-dark;
    cursor: pointer;

    span {
      font-weight: 400;
      font-size: $font-size;
      @media screen and (max-width: 768px) {
        font-size: $font-size-small;
      }
    }

    .radio-title {
      font-weight: $font-weight-bold;
      font-size: $font-size;
      @media screen and (max-width: 768px) {
        font-size: $font-size-small;
      }
    }
  }

  .title-with-icon {
    display: flex;
    align-items: center;

    div {
      flex: 1;
      justify-content: center;
    }

    .circle-icon {
      flex: 0 0 40px;
      z-index: 1;
      display: flex;
      font-size: 1.3em;
      justify-content: center;
      align-items: center;
      height: 40px;
      border-radius: 20px;
      margin-top: 5px;
      color: $font-light;
      background-color: $font;

      &.disabled {
        background-color: $brand-off;
        cursor: not-allowed;
      }
    }
  }

  .title-divider {
    hr {
      margin-top: 0;
      margin-bottom: 20px;
      flex: 1;
      border-top: 2px solid #000000;
    }
  }

  .map {
    width: 100%;
    min-height: 60vh;
    height: 0;

    @media screen and (max-width: 768px) {
      min-height: 40vh;
    }
  }

  rb-checkbox {
    display: block;
    height: 27px;
    width: 27px;
    border: 1px solid #a6a6a6;

    background-color: #eeeeee;

    flex-grow: 0;
    flex-shrink: 0;

    img {
      height: 100%;
      width: 100%;

      vertical-align: baseline;
      opacity: 0;
    }

    &.checked {
      background-color: #8cb922;

      img {
        opacity: 1;
      }
    }
  }

  package-card {
    display: block;
    border: 1px solid #c9c9c9;
    border-radius: 3px;

    header {
      background-color: #eeeeee;

      padding: 13pt;

      div.check-yes {
        font-size: 22px;
        vertical-align: middle;
        display: inline-flex;

        rb-checkbox {
          margin: auto 15pt auto 0;

          vertical-align: middle;
        }

        div.yesplease {
          height: 100%;
          font-weight: 700;

          vertical-align: middle;
        }
      }

      div.price {
        display: inline-block;
        font-size: 22px;

        margin-left: min(35pt);

        vertical-align: middle;
      }

      div.description {
        font-size: 11pt;
        margin-left: 35pt;
      }

      em {
        color: #fd451d;
        font-style: normal;
      }
    }

    div.services {
      padding: 13pt;

      service-selection {
        margin-bottom: 20pt;

        &:last-child {
          margin-bottom: 0;
        }

        em {
          color: #fd451d;
          font-style: normal;
        }
      }
    }

    &.selected {
      div.services rb-checkbox.checked {
        background-color: #c9c9c9;
      }
    }

    &:not(.editable) {
      header div.selection-row rb-checkbox {
        background-color: #c9c9c9;
      }

      div.services rb-checkbox.checked {
        //background-color: #c9c9c9;
      }

      .clickable {
        cursor: not-allowed;
      }
    }
  }

  service-selection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    //column-gap: 10px;
    align-items: center;

    font-size: 18px;

    div.selection {
      display: flex;

      height: 100%;
      min-width: 300px;

      vertical-align: middle;
      line-height: 27px;

      rb-checkbox {
        margin-right: 13px;
      }

      div.yesplease {
        font-weight: 600;
      }
    }

    div.price {
      flex-grow: 6;
      margin-left: 40px;
    }

    &:not(.editable) {
      .clickable {
        cursor: not-allowed;
      }

      rb-checkbox {
        &.checked {
          background-color: #c9c9c9;
        }
      }
    }
  }

  div.services {
    padding: 20pt;

    service-selection {
      margin-bottom: 20pt;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

highlighted-action {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  row-gap: 15pt;

  max-width: 835px;
  margin: 10pt auto;

  border-radius: 3px;

  padding: 15px;

  h3 {
    margin: auto 0;
  }

  button {
    border-radius: 30px;
    padding: 1pt 3pt;
    color: $font-light;
    background-color: #8cb811;
    border-color: #8cb811;
    border: 0;
  }
}

highlighted-heading {
  display: flex;

  justify-content: space-between;

  margin: 20pt auto 5pt auto;

  min-height: 75px;
  padding: 0 20px;

  h3 {
    margin: auto 0;
  }

  div.images {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

collapsible-box {
  display: block;

  > h3.section-heading {
    font-size: 80%;
    padding: 15px;

    margin: 0;
    line-height: 130%;
  }

  > div.description {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;

    > div {
      vertical-align: middle;
    }

    img {
      height: 30px;

      @media screen and (max-width: 768px) {
        height: 25px;
      }
    }
  }

  > div.content {
    padding: 15px;

    img {
      float: right;
      width: 30px;

      @media screen and (max-width: 768px) {
        width: 25px;
      }
    }
  }
}

rb-grid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  row-gap: 10pt;

  > rb-grid-row {
    display: contents;

    > div.key {
      font-weight: bold;
    }
  }
}
